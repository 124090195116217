import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, SVGProps } from 'react';
import { cn } from 'utils/cn';
import type { Custom as CustomIconLookup, IconLookup } from 'utils/fontAwesome';
import { Clock, InfoCircle, MapMarkerAlt, Mobile, User } from '../../../icons/krefel';

export type IconProps = React.HtmlHTMLAttributes<SVGSVGElement> & {
  className?: string;
  icon: IconLookup;
};

const customIcons: Record<CustomIconLookup['name'], FC<SVGProps<SVGSVGElement>>> = {
  clock: Clock,
  'info-circle': InfoCircle,
  'map-marker-alt': MapMarkerAlt,
  'phone-rotary': Mobile,
  user: User,
};

const Icon = ({ className, icon, ...props }: IconProps) => {
  // TODO: Revise if we want to have an approach like this for custom icons or not
  if (icon.styling === 'custom') {
    const CustomIcon = customIcons[icon.name];
    return <CustomIcon className={cn('h-4 w-4', className)} />;
  }

  return (
    <FontAwesomeIcon
      icon={{ iconName: icon.name, prefix: icon.styling }}
      color="currentColor"
      className={cn('size-4 shrink-0', className)}
      {...props}
    />
  );
};

export { Icon };
