import { ProductCardProps, SigningType } from 'components/Product/ProductCard/types';
import SponsoredProductSigning from 'components/Product/SponsoredProductSigning/SponsoredProductSigning';
import * as React from 'react';
import { cn } from 'utils/cn';
import { IconButton } from '../Button/Button';
import { Image, NextImageProps } from '../Image/Image';
import { TextLink, TextLinkProps } from '../Link/Link';
import { Pill } from '../Pill/Pill';
import { Signing } from '../Signing/Signing';
import { Text, TextProps } from '../Text/Text';

const ProductCard = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cn(
        'focus-visible-within-border flex h-full flex-col gap-4 rounded border border-solid border-transparent bg-card p-3 text-foreground hover:border-accent-40 hover:drop-shadow-base',
        className,
      )}
      tabIndex={0}
    />
  ),
);
ProductCard.displayName = 'ProductCard';

const ProductCardImage = React.forwardRef<HTMLImageElement, React.HTMLAttributes<HTMLImageElement> & NextImageProps>(
  ({ alt, className, ...props }, ref) => (
    <Image alt={alt} ref={ref} className={cn('mx-auto object-contain', className)} {...props} />
  ),
);
ProductCardImage.displayName = 'ProductCardImage';

const ProductCardImageWrapper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('relative h-[185px] w-full', className)} {...props} />,
);
ProductCardImageWrapper.displayName = 'ProductCardImageWrapper';

const ProductCardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex flex-col gap-2', className)} {...props} />,
);
ProductCardContent.displayName = 'ProductCardContent';

const ProductCardTitle = React.forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ className, type = 'h4', ...props }, ref) => (
    <TextLink className={cn('line-clamp-2 text-black', className)} ref={ref} type={type} {...props} />
  ),
);
ProductCardTitle.displayName = 'ProductCardTitle';

const ProductCardDescription = React.forwardRef<HTMLDivElement, TextProps & { children: string | string[] }>(
  ({ children, className, type = 'small', ...props }, ref) => {
    const copy = Array.isArray(children) ? children.join(' | ') : children;

    return (
      <Text ref={ref} type={type} className={cn('line-clamp-2 text-accent-60', className)} {...props}>
        {copy}
      </Text>
    );
  },
);
ProductCardDescription.displayName = 'ProductCardDescription';

const ProductCardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('mt-auto flex w-full items-center', className)} {...props} />
  ),
);
ProductCardFooter.displayName = 'ProductCardFooter';

const ProductCardWishlistIconButton = React.forwardRef<
  HTMLButtonElement,
  { className?: string; inWishlist: boolean; onWishlistClick: () => void }
>(({ className, inWishlist, onWishlistClick, ...props }, ref) => (
  <IconButton
    className={cn(inWishlist ? 'text-error' : '', className)}
    onClick={onWishlistClick}
    icon={{ name: 'heart', styling: inWishlist ? 'fas' : 'fal' }}
    ref={ref}
    {...props}
  />
));
ProductCardWishlistIconButton.displayName = 'ProductCardWishlistIconButton';

const ProductCardSigning = React.forwardRef(({ className, signing }: { className?: string; signing: SigningType }) => {
  if (signing?.legalInfo) {
    return <SponsoredProductSigning signing={signing} className={className} />;
  } else {
    return (
      <Signing className={cn(signing.className, className)} style={{ backgroundColor: signing.hex }}>
        {signing.label}
      </Signing>
    );
  }
});
ProductCardSigning.displayName = 'ProductCardSigning';

// TODO: Refactor the logic to another place to keep the ui components as dumb as possible
const ProductPills = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { pills: ProductCardProps['pills'] }
>(({ className, pills }, ref) => {
  if (!pills?.length) return null;

  // If there is only one pill or the first pill is too long, we display it as a single pill
  if (pills?.length === 1 || pills[0].label.length > 32) {
    const pill = pills[0];
    return (
      <Pill ref={ref} className={cn('line-clamp-1')} key={pill.id}>
        {pill.label}
      </Pill>
    );
  } else {
    return (
      <div ref={ref} className={cn('flex gap-2 overflow-hidden', className)}>
        {pills.map((pill, index) => (
          <Pill tag="span" className={cn('overflow-hidden text-ellipsis', index === 0 ? 'shrink-0' : '')} key={pill.id}>
            {pill.label}
          </Pill>
        ))}
      </div>
    );
  }
});
ProductPills.displayName = 'ProductPills';

export {
  ProductCard,
  ProductCardContent,
  ProductCardDescription,
  ProductCardFooter,
  ProductCardImage,
  ProductCardImageWrapper,
  ProductCardSigning,
  ProductCardTitle,
  ProductCardWishlistIconButton,
  ProductPills,
};
