import * as React from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  onClear?: () => void;
  state?: 'error' | 'success';
};

export const searchFieldVariants = tv({
  base: 'placeholder:text-accent-60 w-full pl-5 pr-12 py-3 focus:ring-0 focus-visible:outline-none border border-accent-40 rounded focus:border-accent-40',
  variants: {
    state: {
      error: 'border-secondary-40 bg-secondary-40/[0.05]',
      success: 'border-success bg-success/[0.05]',
    },
  },
});

/**
 * Search component to be used in react-hook-form instances only.
 */
const Search = React.forwardRef<HTMLInputElement, Props>(
  ({ className, name, onClear, type = 'text', value, ...props }, ref) => (
    <div className={cn('relative', className)}>
      <Input className="pr-12" name={name} ref={ref} type={type} value={value} {...props} />

      <div className="absolute right-5 top-1/2 flex -translate-y-1/2 items-center">
        {value && onClear ? (
          <Button variant="ghost" className="p-0" onClick={onClear}>
            <Icon icon={{ name: 'xmark', styling: 'far' }} className="text-accent-60" />
          </Button>
        ) : (
          <Icon icon={{ name: 'search', styling: 'fal' }} className="text-accent-60" />
        )}
      </div>
    </div>
  ),
);

Search.displayName = 'Search';

export { Search };
