import config from 'config';
import { CountryCode } from 'constants/store';
import { useCountries } from 'features/postalCode/queries';
import { useEffect, useState } from 'react';
import { Country } from 'types/Address';
import { findInitialCountry } from 'utils/checkoutUtil';
import CountrySelector from './CountrySelector/CountrySelector';
import { StyledDeliveryPostalCodeWrapper } from './DeliveryPostalCodeSelect.styled';
import PostalCodeSelector from './PostalCodeSelector/PostalCodeSelector';

interface DeliveryPostalCodeSelectProps {
  country?: Country;
  disabled?: boolean;
  formType?: string;
  id: string;
  isCheckout?: boolean;
  label?: string;
  name: string;
  onChangeCountryCallback?: (country: Country) => void;
  onSubmitPostalCode: (value: string, fieldName: string) => void;
  setCountry?: (country: Country) => void;
  withIcons?: boolean;
}

// Make sure to pass always a React state when using with GoogleStreetAutocomplete
const DeliveryPostalCodeSelect = ({
  country = { isocode: config?.defaultCountry as CountryCode },
  disabled = false,
  id,
  isCheckout = false,
  label,
  name,
  onChangeCountryCallback,
  onSubmitPostalCode,
  setCountry,
  withIcons = false,
}: DeliveryPostalCodeSelectProps) => {
  const { data: availableCountries } = useCountries();

  const [selectedValue, setSelectedValue] = useState('');
  const [isChoosingCountry, setIsChoosingCountry] = useState(false);

  const hasCountries = !!availableCountries?.length;

  const setNewCountry = async (country: Country) => {
    setCountry?.(country);

    // Clear input field when country is changed
    name && onSubmitPostalCode('', name);
    setSelectedValue('');

    if (typeof onChangeCountryCallback === 'function') {
      onChangeCountryCallback(country);
    }
  };

  useEffect(() => {
    if (!!availableCountries?.length && !!setCountry) {
      const foundCountry = findInitialCountry(availableCountries, {
        isocode: country?.isocode || (config.defaultCountry as CountryCode),
      });

      foundCountry && setCountry(foundCountry);
    }
  }, [availableCountries, country]);

  return (
    <StyledDeliveryPostalCodeWrapper className={`grid grid-cols-12 ${hasCountries ? 'gx-0' : ''}`}>
      {hasCountries && (
        <div className={isCheckout ? 'col-span-3' : 'col-span-2 lg:col-span-1'}>
          <CountrySelector
            disabled={!!disabled}
            id={id}
            availableCountries={availableCountries}
            selectedCountry={country}
            setIsChoosingCountry={setIsChoosingCountry}
            isChoosingCountry={isChoosingCountry}
            setNewCountry={setNewCountry}
            showCountryIsoCode={isCheckout}
          />
        </div>
      )}

      <div
        className={`postalCodeSelectorWrapper ${
          hasCountries ? `${isCheckout ? 'col-span-9' : 'col-span-10 lg:col-span-11'}` : 'col-span-12'
        }`}
      >
        <PostalCodeSelector
          setSelectedValue={setSelectedValue}
          id={id}
          isChoosingCountry={isChoosingCountry}
          disabled={disabled}
          className={`${hasCountries ? 'with-countries' : ''}`}
          label={label}
          name={name}
          selectedValue={selectedValue}
          withIcons={withIcons}
          selectedCountry={country}
          onSubmitPostalCode={onSubmitPostalCode}
        />
      </div>
    </StyledDeliveryPostalCodeWrapper>
  );
};

export default DeliveryPostalCodeSelect;
