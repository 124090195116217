import { ReactNode } from 'react';
import { cn } from 'utils/cn';
import { Text, TextProps } from '../Text/Text';

type Props = TextProps & {
  children: ReactNode;
  className?: string;
};

const Signing = ({ children, className, type = 'small', ...rest }: Props) => (
  <Text
    type={type}
    className={cn(
      'w-fit items-center gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap rounded fill-white px-2 py-1 text-center font-bold text-white',
      className,
    )}
    {...rest}
  >
    {children}
  </Text>
);

export { Signing };
