import { useSelector } from 'hooks/useSelector';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { destructurePostalCode } from 'utils/postalCodeUtil';
import config from '../config';
import { createPostalCodeString } from '../utils/deliveryAddressUtil';
import useAuthResolver from './useAuthResolver';

const useLocation = (onlyTown = false, onlyPostalCode = false, forceCookie = false): string => {
  const [cookies] = useCookies();
  const [location, setLocation] = useState<string>('');
  const { user } = useAuthResolver();

  const geoPostalCode = useSelector((state) => destructurePostalCode(state?.checkout?.postalCode));

  const userDefaultAddress = user?.defaultAddress;

  useEffect(() => {
    const locationCookie = cookies[config.locationCookie.key];

    if (geoPostalCode && !forceCookie) {
      setLocation(createPostalCodeString(geoPostalCode, onlyTown, onlyPostalCode));
    } else if (locationCookie && forceCookie) {
      setLocation(createPostalCodeString(locationCookie, onlyTown, onlyPostalCode));
    } else if (userDefaultAddress) {
      setLocation(createPostalCodeString(userDefaultAddress, onlyTown, onlyPostalCode));
    }
  }, [cookies, userDefaultAddress, geoPostalCode]);

  return location;
};

export default useLocation;
