import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';

const variants = tv({
  base: 'peer h-6 w-6 shrink-0 rounded-sm border border-accent-100 ring-offset-background focus-visible:outline-none focus-ring disabled:cursor-not-allowed data-[state=checked]:border-primary-30 data-[state=checked]:bg-primary-30 data-[state=checked]:text-white data-[state=checked]:disabled:border-accent-60 data-[state=checked]:disabled:bg-accent-60',
  variants: {
    size: {
      regular: 'h-6 w-6',
      small: 'h-4 w-4',
    },
  },
});

const iconVariants = tv({
  variants: {
    size: {
      regular: 'h-4 w-4',
      small: 'h-2.5 w-2.5',
    },
  },
});

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & { size?: 'regular' | 'small' }
>(({ className, size = 'regular', ...props }, ref) => (
  <CheckboxPrimitive.Root ref={ref} className={variants({ className, size })} checked={props.disabled} {...props}>
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center')}>
      <FontAwesomeIcon className={iconVariants({ size })} icon={faCheck} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
