import React from 'react';
import { cn } from 'utils/cn';
import { Icon } from '../Icon/Icon';
import { Link } from '../Link/Link';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row flex-wrap items-center gap-2', className)} {...props} />
  ),
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & React.ComponentProps<typeof Link>;

const PaginationLink = ({ className, isActive, ...props }: PaginationLinkProps) => (
  <Link
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      'flex h-8 w-8 items-center justify-center rounded-full border border-transparent bg-accent-40 font-medium hover:border-accent-60',
      isActive ? 'border-primary-30 bg-primary-10' : '',
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

type PaginationPreviousProps = {
  disabled?: boolean;
} & React.ComponentProps<typeof PaginationLink>;

const PaginationPrevious = ({ className, disabled, ...props }: PaginationPreviousProps) => (
  <PaginationLink
    className={cn('bg-transparent hover:bg-accent-40', disabled ? 'pointer-events-none opacity-10' : '', className)}
    {...props}
  >
    <Icon icon={{ name: 'angle-left', styling: 'far' }} className="h-4 w-4" />
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

type PaginationNextProps = {
  disabled?: boolean;
} & React.ComponentProps<typeof PaginationLink>;

const PaginationNext = ({ className, disabled, ...props }: PaginationNextProps) => (
  <PaginationLink
    className={cn('bg-transparent hover:bg-accent-40', disabled ? 'pointer-events-none opacity-10' : '', className)}
    {...props}
  >
    <Icon icon={{ name: 'angle-right', styling: 'far' }} className="h-4 w-4" />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={cn('flex h-8 items-end', className)} {...props}>
    <Icon icon={{ name: 'ellipsis', styling: 'fal' }} />
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};
