import { SAVVY_ERROR_CODES } from 'constants/exception';
import { IntlShape } from 'react-intl';
import { Country } from 'types/Address';
import { Schedule } from 'types/Date';
import { StoreDeliverySlot } from 'types/Delivery';
import { BpostPickupPoint, DPDPickupPoint, PostLuPickupPoint, StorePickupPoint } from 'types/DeliveryMethods';
import { FedexPickupPoint } from 'types/Fedex';
import { PaymentMethod } from 'types/Payment';
import {
  DELIVERY_METHODS,
  DELIVERY_TIMEFRAMES,
  DeliveryMethodCode,
  DeliveryTimeFrameInfoOption,
} from '../constants/checkout';

export const findInitialCountry = (countries: Country[], country: Country) =>
  countries?.find((c) => c?.isocode === country?.isocode);

export const validateDeliveryDates = (date: Date, deliverySlots: StoreDeliverySlot[]) => {
  if (!deliverySlots?.length) {
    return false;
  }

  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);

  return !!deliverySlots.find((slot) => {
    const slotNormalizedDate = new Date(slot?.deliveryDate);
    slotNormalizedDate.setHours(0, 0, 0, 0);

    const isEqual = slotNormalizedDate.getTime() === normalizedDate.getTime();

    if (slot?.available === false) {
      return false;
    }

    return isEqual;
  });
};

export const getMethodFromPaymentMethodList = (
  paymentMethodsList: PaymentMethod[],
  paymentMethodCode: string,
  shouldExactMatch = false,
): PaymentMethod | undefined => {
  if (shouldExactMatch) {
    return paymentMethodsList?.find(({ code }) => code === paymentMethodCode);
  }
  return paymentMethodsList?.find(({ code }) => code?.includes(paymentMethodCode));
};

export const getDeliveryMessageFromSchedule = ({
  formatMessage,
  schedule,
}: {
  formatMessage: IntlShape['formatMessage'];
  schedule?: Schedule;
}) => {
  const scheduleDay = schedule?.day;
  const scheduleHours = schedule?.hours;

  const closingTime = scheduleHours?.closingTime?.hour;
  const openingTime = scheduleHours?.openingTime?.hour;

  if (!schedule || !(scheduleDay && scheduleHours)) {
    return null;
  }

  if (scheduleDay === DELIVERY_TIMEFRAMES.TODAY && closingTime) {
    return formatMessage({ id: `checkout_${DELIVERY_METHODS.HQ_PICK_UP}_promise_today` }, { hour: closingTime });
  }

  if (openingTime) {
    return formatMessage(
      {
        id:
          scheduleDay === DELIVERY_TIMEFRAMES.TOMORROW
            ? `checkout_${DELIVERY_METHODS.HQ_PICK_UP}_promise_tomorrow`
            : `checkout_${DELIVERY_METHODS.HQ_PICK_UP}_promise`,
      },
      {
        closingHour: scheduleHours.closingTime?.hour,
        date: scheduleDay,
        openingHour: openingTime + 1,
      },
    );
  }
};

export const getGiftcardPaymentErrorMessage = (giftcardErrorCode: string | number): string => {
  switch (giftcardErrorCode) {
    case SAVVY_ERROR_CODES.CARD_EXPIRED:
      return 'gift_card_submit_error_expired';
    case SAVVY_ERROR_CODES.CARD_MERCHANT_MISMATCH_OR_CARD_RESTRICTED:
      return 'gift_card_submit_error_mismatch';
    case SAVVY_ERROR_CODES.ZERO_BALANCE:
      return 'gift_card_submit_error_zero_balance';
    case 1:
      return 'gift_cart_remove_error';
    default:
      return 'gift_card_submit_error';
  }
};

export const getDeliveryOptions = (
  deliveryMethodCode: DeliveryMethodCode,
  pickupPoint: StorePickupPoint | BpostPickupPoint | DPDPickupPoint | PostLuPickupPoint | FedexPickupPoint,
) => {
  switch (deliveryMethodCode) {
    case DELIVERY_METHODS.PICK_UP_IN_STORE: {
      const point = pickupPoint as StorePickupPoint;
      return {
        firstAvailablePickupInHours: point?.firstAvailablePickupInHours,
        pointOfServiceId: point?.krefelId,
        postalCode: point?.address.postalCode,
      };
    }
    case DELIVERY_METHODS.BPOST_PICK_UP: {
      const point = pickupPoint as BpostPickupPoint;
      return {
        id: point?.record?.id,
        postalCode: point?.record?.zip,
        type: point?.record?.type,
      };
    }

    case DELIVERY_METHODS.DPD_PACK_UP: {
      const point = pickupPoint as DPDPickupPoint;
      return point;
    }

    case DELIVERY_METHODS.FEDEX_PACK_UP: {
      const point = pickupPoint as FedexPickupPoint;
      return point;
    }

    case DELIVERY_METHODS.POSTLU_PACK_UP: {
      const point = pickupPoint as PostLuPickupPoint;
      return {
        id: point?.id,
        postalCode: point?.address?.postalCode,
      };
    }

    default:
      return null;
  }
};

export const getDeliveryTimeFrameInfo = (deliveryTimeFrame: DeliveryTimeFrameInfoOption) => {
  if (!deliveryTimeFrame) {
    return null;
  }

  const labels: Record<DeliveryTimeFrameInfoOption, string> = {
    afternoon: 'basket_overview_afternoon_delivery',
    allDay: 'basket_overview_Krefel_delivery_all_day_delivery',
    morning: 'basket_overview_morning_delivery',
  };

  return labels[deliveryTimeFrame];
};
