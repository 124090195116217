import AnalyticsWrapper from 'components/Analytics/AnalyticsWrapper/AnalyticsWrapper';
import Link from 'components/Link/Link';
import { ANALYTIC_EVENTS } from 'constants/analytics';
import { APP_EVENT_MESSAGES } from 'constants/appEvents';
import useAppEvents from 'hooks/useAppEvents';
import { useCriteoTracking } from 'hooks/useCriteoTracking';
import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import { Atp, CriteoTracking, ProductBrand } from 'types/Product';
import { createProductInfoObject } from 'utils/analyticsProductDataUtil';
import { prefixWithLocale } from 'utils/urlUtil';
import { detectEmbedded } from 'utils/windowUtil';
import { StyledProductLinkWrapper } from './ProductLink.styled';

interface ProductLinkProps {
  atp?: Atp;
  brand?: ProductBrand;
  carrier?: string;
  children: ReactNode;
  className?: string;
  code?: string;
  dataLayerCategoryPath?: string;
  id?: string;
  name?: string;
  newTab?: boolean;
  onClick?: () => void;
  price?: object | number;
  quantity?: number;
  queryId?: string;
  replaceUrl?: boolean;
  tracking?: CriteoTracking;
  url?: string;
}

type UrlPath = {
  pathname?: string;
  query?: {
    queryId?: string;
  };
};

const ProductLink = ({
  atp,
  brand,
  carrier,
  children,
  className,
  code,
  dataLayerCategoryPath,
  id,
  name,
  newTab,
  onClick,
  price,
  quantity,
  queryId,
  replaceUrl,
  tracking,
  url,
}: ProductLinkProps) => {
  const { locale } = useRouter();
  const isWebview = detectEmbedded();
  const { sendAppEvent } = useAppEvents();
  const { pushCriteoBeacon } = useCriteoTracking();

  let path: UrlPath = { pathname: url };

  if (queryId) {
    path = {
      ...path,
      query: { queryId },
    };
  }

  const handleClick = () => {
    if (tracking && tracking.ProductPage) pushCriteoBeacon?.(tracking.ProductPage);
    if (isWebview) {
      code &&
        sendAppEvent({
          message: APP_EVENT_MESSAGES.OPEN_PRODUCT,
          params: { IdProduct: code },
        });
    }

    onClick?.();
  };

  return (
    <AnalyticsWrapper>
      <StyledProductLinkWrapper
        className={`product-link ${className || ''}`}
        data-analytics-event={ANALYTIC_EVENTS.PRODUCT_CLICK}
        data-suggestion-name={brand ? `${brand.name} ${name}` : `${name}`}
        data-tracking-commerce={createProductInfoObject(
          name,
          code,
          price,
          brand,
          dataLayerCategoryPath,
          atp,
          carrier,
          quantity,
        )}
        data-tracking-info={url && prefixWithLocale(url, locale)}
      >
        <Link
          className={`product-link ${className || ''}`}
          id={id}
          newTab={newTab}
          onClick={handleClick}
          replaceUrl={replaceUrl}
          url={path}
        >
          {children}
        </Link>
      </StyledProductLinkWrapper>
    </AnalyticsWrapper>
  );
};

export default ProductLink;
