import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'utils/cn';

const badgeVariants = cva(
  'w-fit rounded-lg bg-accent-20 p-2 border-transparant focus:border-2 focus:border-ring focus:border-offset-2 leading-5',
  {
    defaultVariants: {
      variant: 'small',
    },
    variants: {
      variant: {
        'in-tile': 'font-bold px-4 py-1 rounded-br-none rounded-tl-none rounded-bl rounded-tr',
        large: 'font-bold',
        small: 'py-1 text-xs',
      },
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  className?: string;
}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
