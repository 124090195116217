import Box from 'components/Box/Box';
import Button from 'components/Button/Button';
import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import SelectableIcon from 'components/Icon/SelectableIcon/SelectableIcon';
import Typography from 'components/Typography/Typography';
import { ICON_NAMES } from 'constants/icon';
import { useProduct } from 'features/product';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { useIntl } from 'react-intl';
import { Store } from 'types/Store';
import { Dialog, DialogContent, DialogTrigger } from 'ui';
import { getPickupInStoreHourMessage } from 'utils/deliveryAddressUtil';
import OpeningTextSchedule from '../../Store/OpeningTextSchedule/OpeningTextSchedule';
import {
  StyledPickupInStoreItem,
  StyledPickupInStoreItemHourMessage,
  StyledPickupInStoreItemInfo,
  StyledPickupInStoreItemInfoAtc,
  StyledPickupInStoreItemStockItemMessage,
} from './PickupInStoreItem.styled';

interface PickupInStoreItemProps {
  isFavoriteStoreSelect?: boolean;
  onFavouriteButtonClick: (store: Store) => void;
  onSelectStore: (store: Store) => void;
  showOpeningHours?: boolean;
  store: Store;
  town?: string;
}

const PickupInStoreItem = ({
  isFavoriteStoreSelect,
  onFavouriteButtonClick,
  onSelectStore,
  showOpeningHours = true,
  store,
  town,
}: PickupInStoreItemProps) => {
  const { formatMessage } = useIntl();
  const { locale } = useRouter();

  const productOnOrderCart = useSelector((state) => state.cart?.cart?.productOnOrderCart);
  const { data: product } = useProduct();

  const { user } = useAuthResolver();
  const favouriteStores = user?.favouriteStores;
  const isFavouriteStore = favouriteStores?.some((favStore) => favStore?.krefelId === store?.krefelId);

  const message = getPickupInStoreHourMessage(
    locale,
    store?.firstAvailablePickupDate,
    store?.firstAvailablePickupInHours,
  );

  return (
    <StyledPickupInStoreItem>
      <Flex flexDirection="column">
        {store?.formattedDistance && town && (
          <Typography marginBottom={75}>
            {formatMessage(
              { id: 'delivery_pickup_in_store_pickup_distance' },
              {
                km: store.formattedDistance,
                location: town,
              },
            )}
          </Typography>
        )}
        <Typography fontWeight="bold">{store.displayName}</Typography>
        {!!store.address && (
          <>
            <Typography>
              {store.address.line1} {store.address.line2}
            </Typography>

            <Typography>
              {store.address.postalCode} {store.address.town}
            </Typography>
          </>
        )}

        {showOpeningHours && store.openingHours?.weekDayOpeningList && (
          <Box marginTop={100}>
            <OpeningTextSchedule weekDayOpeningList={store.openingHours?.weekDayOpeningList} />
          </Box>
        )}
      </Flex>

      {isFavoriteStoreSelect ? (
        <SelectableIcon
          active={isFavouriteStore}
          ariaLabel={formatMessage({ id: 'favorite_store_icon_label' })}
          iconName={isFavouriteStore ? ICON_NAMES.HEART_FULL : ICON_NAMES.HEART}
          iconSize={150}
          onClick={() => onFavouriteButtonClick && onFavouriteButtonClick(store)}
          showCircle
        />
      ) : (
        <StyledPickupInStoreItemInfo>
          <Flex alignItems="flex-start" flexDirection="column">
            <StyledPickupInStoreItemStockItemMessage color="ocean-400" fontWeight="bold" textAlign="right">
              {formatMessage({
                id: 'atp_message_click_collect',
              })}
            </StyledPickupInStoreItemStockItemMessage>

            {productOnOrderCart || product?.isPrestige ? (
              <Typography color="ocean-400">{formatMessage({ id: 'pickup_in_store_on_order_message' })}</Typography>
            ) : (
              <StyledPickupInStoreItemHourMessage>
                {!!message?.length && (
                  <Typography color="ocean-400">{formatMessage(message[0], message[1])}</Typography>
                )}

                {store.firstAvailablePickupInHours !== 1 && (
                  <Dialog>
                    <DialogTrigger>
                      <Icon type="custom" name={ICON_NAMES.INFO_CIRCLE} size={100} />
                    </DialogTrigger>
                    <DialogContent>
                      <div className="pt-2">
                        {formatMessage({
                          id: 'atp_message_stock_not_in_hours_info_description',
                        })}
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </StyledPickupInStoreItemHourMessage>
            )}
          </Flex>
          <StyledPickupInStoreItemInfoAtc>
            <Button onClick={() => onSelectStore && onSelectStore(store)}>
              {formatMessage({
                id: 'delivery_pickup_in_store_pickup',
              })}
            </Button>
          </StyledPickupInStoreItemInfoAtc>
        </StyledPickupInStoreItemInfo>
      )}
    </StyledPickupInStoreItem>
  );
};

export default PickupInStoreItem;
