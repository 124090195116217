import * as React from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Skeleton } from '../Skeleton/Skeleton';

export const textVariants = {
  body: 'font-body text-[1rem] leading-[1.5rem]',
  h1: 'font-h1 font-bold text-[1.5rem]',
  h2: 'font-h2 font-bold text-[1.5rem]',
  h3: 'font-h3 font-bold text-[1.25rem] leading-[1.25rem]',
  h4: 'font-h4 font-bold text-[1rem] leading-[1.25rem]',
  small: 'font-body text-[0.875rem] leading-[1.25rem]',
};

export const textStyling = tv({
  base: 'm-0 p-0 text-foreground',
  variants: {
    type: textVariants,
  },
});

const loadingVariants = tv({
  variants: {
    type: {
      body: 'h-[1.5rem]',
      h1: 'h-[1.5rem]',
      h2: 'h-[1.5rem]',
      h3: 'h-[1.25rem]',
      h4: 'h-[1.25rem]',
      small: 'h-[0.875rem]',
    },
  },
});

export type TextProps = {
  children?: React.ReactNode;
  className?: string;
  html?: string;
  htmlFor?: string;
  loading?: boolean;
  loadingClassName?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  tag?: FontTag;
  type?: FontType;
} & React.HTMLAttributes<HTMLSpanElement>;

type FontTag = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'label' | 'div';

export type FontType = 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'small';

const typoTags: { [key in FontType]: FontTag } = {
  body: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  small: 'p',
};

const getTag = (key: FontType) => typoTags[key];

// Exceptionally use any to allow multiple types of refs
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Text = React.forwardRef<any, TextProps>(
  ({ children, className, html, htmlFor, loading = false, loadingClassName, tag, type = 'body', ...rest }, ref) => {
    const CustomTag = tag || getTag(type);

    if (loading) {
      return (
        <Skeleton
          className={loadingVariants({
            className: cn(className, loadingClassName),
            type,
          })}
        />
      );
    }

    if (html) {
      return (
        <span
          className={cn(
            'max-w-full [&_a]:text-link-base hover:[&_a]:text-link-hover focus:[&_a]:border-0 focus-visible:[&_a]:border-2 focus-visible:[&_a]:border-accent-100 [&_ul>li]:ml-4 [&_ul>li]:list-disc',
            textStyling({ className, type }),
          )}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          ref={ref}
          {...rest}
        />
      );
    }

    return (
      <CustomTag className={textStyling({ className, type })} htmlFor={htmlFor} ref={ref} {...rest}>
        {children}
      </CustomTag>
    );
  },
);

Text.displayName = 'Text';

export { Text };
