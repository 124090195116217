import * as React from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';
import { LinkProps, TextLink } from '../Link/Link';
import { textVariants } from '../Text/Text';

const buttonVariants = tv({
  base: `${textVariants.body} border text-foreground font-medium gap-2 inline-flex items-center justify-center whitespace-nowrap border-offset-background transition-colors rounded-full focus-visible:outline-none focus-ring`,
  compoundVariants: [
    { className: 'pointer-events-none border-transparent ring-transparent', disabled: true },
    {
      className: 'bg-accent-60 text-accent-40',
      disabled: true,
      variant: ['primary', 'secondary'],
    },
    {
      className: 'bg-accent-40 text-accent-60',
      disabled: true,
      variant: ['tertiary', 'outlined'],
    },
  ],
  defaultVariants: {
    size: 'large',
    variant: 'primary',
  },
  variants: {
    disabled: { true: '' },
    size: {
      large: 'px-6 py-3',
      small: 'px-4 py-2',
    },
    variant: {
      ghost:
        'border-0 text-button-ghost-default-foreground hover:text-button-ghost-hover-foreground focus-visible:px-1',
      outlined:
        'bg-button-outlined-default-background text-button-outlined-default-foreground border-button-outlined-default-border hover:bg-button-outlined-hover-background',
      primary:
        'bg-button-primary-default-background text-button-primary-default-foreground border-button-primary-default-border hover:bg-button-primary-hover-background hover:text-button-primary-hover-foreground hover:border-button-primary-hover-border font-bold',
      secondary:
        'bg-button-secondary-default-background text-button-secondary-default-foreground border-button-secondary-default-border hover:bg-button-secondary-hover-background hover:text-button-secondary-hover-foreground hover:border-button-secondary-hover-border',
      tertiary:
        'bg-button-tertiary-default-background text-button-tertiary-default-foreground border-button-tertiary-default-border hover:bg-button-tertiary-hover-background hover:text-button-tertiary-hover-foreground hover:border-button-tertiary-hover-border',
    },
  },
});

type ButtonProps = Omit<React.InputHTMLAttributes<HTMLButtonElement>, 'size'> & {
  asChild?: boolean;
  disabled?: boolean;
  href?: LinkProps['href'];
  icon?: IconProps['icon'];
  iconClassName?: string;
  iconPosition?: 'before' | 'after';
  loading?: boolean;
  locale?: string;
  newTab?: boolean;
  size?: 'small' | 'large';
  type?: 'button' | 'reset' | 'submit' | undefined;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'outlined';
};

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      href,
      icon,
      iconClassName,
      iconPosition = 'before',
      loading,
      locale,
      newTab,
      size,
      type = 'button',
      variant,
      ...props
    },
    ref,
  ) => {
    const loadingComponent = (
      <Icon className={cn('h-4 w-4 animate-spin', iconClassName)} icon={{ name: 'spinner-third', styling: 'far' }} />
    );
    const iconComponent = !!icon && <Icon className={cn('h-4 w-4', iconClassName)} icon={icon} />;
    const classNameProp = buttonVariants({ className, disabled, size, variant });

    const content = (
      <>
        {iconPosition === 'before' && <>{loading ? loadingComponent : iconComponent}</>}
        {children}
        {iconPosition === 'after' && <>{loading ? loadingComponent : iconComponent}</>}
      </>
    );

    if (href) {
      return (
        <TextLink
          newTab={newTab}
          className={classNameProp}
          href={href}
          locale={locale}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        >
          {content}
        </TextLink>
      );
    }

    return (
      <button
        {...props}
        disabled={disabled || loading}
        className={classNameProp}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        type={type}
      >
        {content}
      </button>
    );
  },
);

const buttonIconVariants = tv({
  base: 'flex size-8 p-0 items-center justify-center rounded-full border border-transparent bg-accent-40 fill-accent-100 hover:border-accent-60',
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: 'bg-accent-40 fill-accent-100 hover:bg-accent-60',
      ghost: 'bg-transparent',
    },
  },
});

type ButtonIconProps = ButtonProps & {
  icon: IconProps['icon'];
  variant?: 'default' | 'ghost';
};

const IconButton = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ className, icon, type = 'button', variant, ...props }, ref) => (
    <button
      className={cn(
        buttonIconVariants({ className, variant }),
        'focus-ring disabled:accent-20 disabled:opacity-50 disabled:hover:border-0',
      )}
      ref={ref}
      type={type}
      {...props}
    >
      <Icon icon={icon} />
    </button>
  ),
);

IconButton.displayName = 'IconButton';

export { Button, IconButton };
