/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable @next/next/no-img-element */

import NextImage, { type ImageProps } from 'next/image';
import { forwardRef } from 'react';
import { ImageSizes } from 'types/Image';
import { cn } from 'utils/cn';
import { getImageSizes, imageLoader } from 'utils/imageUtil';

export type NextImageProps = ImageProps & {
  nextSizes?: ImageSizes;
  prefixUrl?: boolean;
  src: string;
  useNext?: boolean;
};

const Image = forwardRef<HTMLImageElement, NextImageProps>(
  ({ alt, className, fill = false, height, nextSizes, sizes, src, useNext = false, width, ...props }, ref) => {
    if (!src) {
      return null;
    }

    if (useNext) {
      return (
        <NextImage
          alt={alt}
          className={className}
          fill={fill}
          height={height}
          quality={80}
          sizes={fill ? getImageSizes(nextSizes) : undefined}
          loader={imageLoader}
          src={src}
          width={width}
          {...props}
        />
      );
    }

    return (
      <img
        ref={ref}
        alt={alt}
        className={cn('relative', fill ? 'h-full w-full' : '', className)}
        height={fill ? '100%' : height}
        src={src.toString()}
        srcSet={sizes}
        width={fill ? '100%' : width}
      />
    );
  },
);

export { Image };
