import { VariantProps, cva } from 'class-variance-authority';
import { useRef } from 'react';
import { cn } from 'utils/cn';
import { Text, TextProps } from '../Text/Text';

const pillVariants = cva('w-fit px-3 py-1 line-clamp-1', {
  defaultVariants: {
    radius: 'default',
    variant: 'default',
  },
  variants: {
    radius: {
      default: 'rounded-pill',
      large: 'rounded-pill-large',
    },
    variant: {
      default: 'bg-primary-30/10',
      outline: 'border border-accent-60 text-primary',
    },
  },
});

const onClickStyling =
  'cursor-pointer hover:bg-primary-30/20 hover:border-primary-30 focus-border focus-visible:!ml-1 focus-visible:!mt-1';
const activeStyling = 'bg-primary-30/20 border-primary-30';

type Props = TextProps & {
  active?: boolean;
  children: React.ReactNode;
  // Ref type set to any because of Text component's ref is also set to any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
} & VariantProps<typeof pillVariants>;

const Pill = ({
  active,
  children,
  className,
  onClick,
  radius = 'default',
  ref,
  tag = 'div',
  type = 'small',
  variant,
  ...textProps
}: Props) => {
  const textRef = useRef<HTMLElement>(null);

  return (
    <Text
      ref={ref ?? textRef}
      tabIndex={0}
      type={type}
      tag={tag}
      onClick={onClick}
      className={cn(
        pillVariants({ radius, variant }),
        // Do these belong here or are they intrinsic to FacetColorPill?
        onClick ? onClickStyling : '',
        active ? activeStyling : '',
        className,
      )}
      onKeyUp={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          textRef.current?.click();
        }
      }}
      {...textProps}
    >
      {children}
    </Text>
  );
};

export { Pill };
