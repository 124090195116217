import { type ReactNode } from 'react';

export type Props = {
  amount?: number;
  children: ReactNode;
};

const Repeat = ({ amount = 1, children }: Props) => (
  <>{Array(amount).fill(children)}</>
);

export default Repeat;
