import { useIntl } from 'react-intl';
import { CriteoLegalInfo } from 'types/Criteo';
import { Dialog, DialogContent, DialogHeader, DialogTitle, Text } from 'ui';

interface CriteoLegalModalProps {
  legalInfo: CriteoLegalInfo;
  onClose: () => void;
  open: boolean;
}

const CriteoLegalModal = ({ legalInfo, onClose, open }: CriteoLegalModalProps) => {
  const { formatMessage } = useIntl();
  const { behalf, paid } = legalInfo;

  const modalContent = formatMessage({ id: 'general_criteo_ad_modal_text' });
  // Manually replace because the message contains HTML
  const enrichedModalContent = modalContent.replace('{behalf}', behalf).replace('{paid}', paid);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({
              id: 'general_criteo_ad_modal_title',
            })}
          </DialogTitle>
        </DialogHeader>

        <Text html={enrichedModalContent} />
      </DialogContent>
    </Dialog>
  );
};
export default CriteoLegalModal;
